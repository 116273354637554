var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=container.lambda;

  return "<div class=\"w-layout-grid grid_feature-main_right grid_nomarginbottom\" id=\""
    + alias1(((helper = (helper = helpers.idBase || (depth0 != null ? depth0.idBase : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"idBase","hash":{},"data":data}) : helper)))
    + "-group\">\n    <div id=\"w-node-912342e38749-2f599c7e\" class=\"main_feature-small-1-food\"\n         style=\"background:linear-gradient(rgba(20,20,20, .4),rgba(20,20,20, .4)), url("
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.image_url : stack1), depth0))
    + ") center / cover no-repeat;\">\n        <a href=\""
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.main_object_url : stack1), depth0))
    + " \" class=\"black\">\n            <div class=\"img_gradient-cover-general\">\n                <div class=\"feature_maincontent-small\">\n                    <h5 class=\"h5 light\">"
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.title : stack1), depth0))
    + "</h5>`\n                </div>\n            </div>\n        </a>\n    </div>\n    <div id=\"w-node-912342e3874d-2f599c7e\" class=\"main_feature-small-2\"\n         style=\"background:linear-gradient(rgba(20,20,20, .4),rgba(20,20,20, .4)), url("
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.image_url : stack1), depth0))
    + ") center / cover no-repeat;\">\n        <a href=\""
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.main_object_url : stack1), depth0))
    + " \" class=\"black\">\n            <div class=\"img_gradient-cover-general\">\n                <div class=\"feature_maincontent-small\">\n                    <h5 class=\"h5 light\">"
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.title : stack1), depth0))
    + "</h5>`\n                </div>\n            </div>\n        </a>\n    </div>\n    <div id=\"w-node-912342e38745-2f599c7e\" class=\"main_feature-large-food\"\n         style=\"background:linear-gradient(rgba(20,20,20, .4),rgba(20,20,20, .4)), url("
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.image_url : stack1), depth0))
    + ") center / cover no-repeat;\">\n        <a href=\""
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.main_object_url : stack1), depth0))
    + " \" class=\"black\">\n            <div class=\"img_gradient-cover-general\">\n                <div class=\"feature_main-content feature_titleonly\">\n                    <h2 class=\"h2 light\">"
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.title : stack1), depth0))
    + "</h2>\n                    <p class=\"p_s light\">"
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.excerpt : stack1), depth0))
    + "</p>\n                </div>\n            </div>\n        </a>\n    </div>\n</div>\n";
},"useData":true});