import {Search, changeTab} from './search'
import {initInfiniteArticles} from './infinite-articles'
import {initInfiniteHotels, initHotelsPage} from  './hotels'
import {initLocalExperiences} from './destinations-base'

export {
    Search,
    changeTab,
    initInfiniteArticles,
    initInfiniteHotels,
    initHotelsPage,
    initLocalExperiences,
}
