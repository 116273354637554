import algoliasearch from 'algoliasearch'

import * as recommendedHotels from './templates/destinations/recommended-hotels.hbs'
import * as hotelsBanner from './templates/destinations/hotels-banner-single.hbs'
import * as singleBanner from './templates/destinations/banner-single.hbs'
import * as foodTriple from './templates/destinations/food-wine-triple.hbs'

let indexPrefix, analytics, searchClient, destinationFilters, lineageFilters, foodAndWineFilters,
    localAdventuresFilters;
const indexReplica = '-engagement';

const foodAndWineSelector = '#food-wine-container';
const localAdventuresSelector = '#local-adventures-container';
const localItinerariesSelector = '#local-itineraries-container';
const recommendedHotelsSelector = '#recommended-hotels-container';
const countSelector = '.results-count';

const mapPropertyCountSelector = '#property-count';

let food_wine_hits = [];

const setConfigParams = (options) => {
    searchClient = algoliasearch(options.appId, options.apiKey);
    indexPrefix = options.indexPrefix || '';
    analytics = options.analytics;

    destinationFilters = options.destinationTags.map(tag => `tags:"${tag}"`).join(' OR ');
    lineageFilters = options.lineageTags.map(dest => `lineage:"${dest}"`).join(' AND ');
    foodAndWineFilters = options.foodAndWineTags.map(tag => `tags:"${tag}"`).join(' OR ');
    localAdventuresFilters = options.localAdventuresTags.map(tag => `tags:"${tag}"`).join(' OR ');

    const nowInSeconds = Math.round((new Date()).getTime() / 1000);
    const dateFilter = `main_object_publish_date<${nowInSeconds}`;


    if (destinationFilters) {
        foodAndWineFilters = `${dateFilter} AND (${destinationFilters}) AND (${foodAndWineFilters})`;
        localAdventuresFilters = `${dateFilter} AND (${destinationFilters}) AND (${localAdventuresFilters})`;
    }
    else {
        foodAndWineFilters = `${dateFilter} AND (${foodAndWineFilters})`;
        localAdventuresFilters = `${dateFilter} AND (${localAdventuresFilters})`;
    }
};

function foodAndWineFeature(feature, hit) {
    feature.css('background-image', `url("${hit.image_url}")`);
    feature.find('a').attr('href', hit.main_object_url);
    feature.find('a h2, a h5').text(hit.title);
    feature.find('a p').text(hit.excerpt);
}

const toggleFoodAndWine = (err, results) => {
    let container = $(foodAndWineSelector);
    let hits = results.hits;
    container.find(countSelector).text(`(${results.nbHits})`);
    if (results.nbHits>=3){
        container.append(foodTriple({
            idBase:'food-wine',
            results: results.hits,
        }));
        container.slideDown();
        food_wine_hits = hits.slice(0, 3);
    }
    else if (results.nbHits){
        container.append(singleBanner({
            idBase:'food-wine',
            results: results.hits,
        }));
        container.slideDown();
        food_wine_hits = hits.slice(0, 1);
    }
};

const toggleLocalAdventures = (err, results) => {
    // To prevent duplicate articles being displayed in Food & Wine and Local Adventures, we filter the
    // selected results out here. Due to the async nature, it is possible that this is missed, but it's
    // rare enough that the delay caused by forcing synchronous execution would be worse.
    let food_and_wine_ids = food_wine_hits.map(hit => hit.main_object_id);
    let length = Math.max(2*food_wine_hits.length, 3);
    let hits = results.hits.slice(0, length)
        .filter(hit => !food_and_wine_ids.includes(hit.main_object_id));

    if (hits.length){
        let container = $(localAdventuresSelector);

        container.find(countSelector).text(`(${results.nbHits})`);
        container.append(singleBanner({
            idBase:'local-adventures',
            results: hits,
        }));
        container.slideDown();
    }
};

const toggleLocalItineraries = (err, results) => {
    if (results.nbHits){
        let container = $(localItinerariesSelector);
        container.find(countSelector).text(`(${results.nbHits})`);
        container.append(singleBanner({
            idBase:'local-itineraries',
            results: results.hits,
        }));
        container.slideDown();
    }
};

const toggleRecommendedHotels = (err, results) => {
    let prop_cnt_span = $(mapPropertyCountSelector);
    prop_cnt_span.text(results.nbHits);

    let container = $(recommendedHotelsSelector);
    container.find(countSelector).text(`(${results.nbHits})`);
    if (results.nbHits>=2){
        container.append(recommendedHotels({
            idBase:'local-hotels',
            results: results.hits,
        }));
        container.slideDown();
        prop_cnt_span.fadeTo(500,1);
    }
    else if (results.nbHits){
        container.append(hotelsBanner({
            idBase:'local-hotels',
            results: results.hits,
        }));
        container.slideDown();
        prop_cnt_span.fadeTo(500,1);
    }

};

const initLocalExperiences = (options) => {
    setConfigParams(options);
    let articleIndex = searchClient.initIndex(`${indexPrefix}articles${indexReplica}`);
    let itinerariesIndex = searchClient.initIndex(`${indexPrefix}itineraries${indexReplica}`);
    let hotelsIndex = searchClient.initIndex(`${indexPrefix}hotels-dated`);

    articleIndex.search(
        {filters:foodAndWineFilters},
        toggleFoodAndWine
    );

    articleIndex.search(
        {filters:localAdventuresFilters},
        toggleLocalAdventures
    );

    itinerariesIndex.search(
        {filters:lineageFilters},
        toggleLocalItineraries
    );

    hotelsIndex.search(
        {filters:lineageFilters},
        toggleRecommendedHotels
    );

};

export {
    initLocalExperiences
}
