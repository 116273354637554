import algoliasearch from 'algoliasearch'

import {getArticlesSearchFunction, getConfigureWidget, getCustomInifinteHits, getInstantSearch} from './common'
import * as articleTemplate from './templates/article.hbs'


let indexPrefix, analytics, searchClient, tags, destinationTags

const setConfigParams = (options) => {
    searchClient = algoliasearch(options.appId, options.apiKey)
    indexPrefix = options.indexPrefix || ''
    analytics = options.analytics
    tags = options.tags
    destinationTags = options.destinationTags
}

const initInfiniteArticles = (options) => {
    setConfigParams(options)
    const indexName = 'articles'
    const search = getInstantSearch({
        searchClient,
        indexPrefix,
        indexName,
        hitsPerPage: 10,
        searchFunction: getArticlesSearchFunction(indexName)
    })

    // Create the custom widget
    const customInfiniteHits = getCustomInifinteHits({template: articleTemplate})

    // Instantiate the custom widget
    search.addWidget(customInfiniteHits({container: '.infinite-hits', showPrevious: true}))
    let filters = tags.map(tag => `tags:"${tag}"`).join(' OR ')
    let destinationFilters = destinationTags.map(tag => `tags:"${tag}"`).join(' OR ')
    filters = filters ?  `(${destinationFilters}) AND (${filters})` : `${destinationFilters}`
    search.addWidget(getConfigureWidget({analytics, filters}))

    search.start()
    $('#articlesSorter').change(() => {
        $('.infinite-hits').empty()
        search.helper.search()
    })
}

export {
    initInfiniteArticles
}
