import instantsearch from 'instantsearch'


const getInstantSearch = ({searchClient, indexPrefix, indexName, hitsPerPage, routing, searchFunction, facets, disjunctiveFacets}) => {
    hitsPerPage = hitsPerPage || 8
    indexName = `${indexPrefix}${indexName}`
    return instantsearch({
        searchClient,
        indexName,
        routing,
        searchFunction,
        searchParameters: {
            hitsPerPage,
            facets,
            disjunctiveFacets,
        }
    })
}

const getArticlesSearchFunction = (baseIndexName, nestedSearchers) => (helper) => {
    const query = helper.state.query
    if (nestedSearchers) {
        nestedSearchers.map((instantSearch) => {
            instantSearch.helper.setQuery(query)
            instantSearch.helper.search()
        })
    }

    const currentIndex = helper.state.index
    const sortBy = $('#articlesSorter').val()
    let replica
    switch (sortBy) {
        case 'recommended':
            replica = 'engagement'
            break
        case 'newest':
            replica = 'dated'
            break
        case 'oldest':
            replica = 'oldest'
            break
        default:
            replica = 'engagement'
            break
    }

    const newIndex = `${baseIndexName}${replica === '' ? '' : `-${replica}`}`

    if (currentIndex !== newIndex) {
        helper.setIndex(newIndex)
    }
    helper.search()
}

const getHotelsSearchFunction = (baseIndexName) => (helper) => {
    const query = helper.state.query
    const currentIndex = helper.state.index
    const sortBy = $('#hotelsSorter').val()
    let replica
    switch (sortBy) {
        case 'editor-rating':
            replica = 'rating'
            break
        case 'lowest-price':
            replica = 'lowest-price'
            break
        case 'highest-price':
            replica = 'highest-price'
            break
        default:
            replica = query === '' ? 'dated' : ''
            break
    }
    const newIndex = `${baseIndexName}${replica === '' ? '' : `-${replica}`}`
    if (currentIndex !== newIndex) {
        helper.setIndex(newIndex)
    }
    helper.search()
}

const getCustomInifinteHits = ({template, showMoreSelector = '#showMore', infiniteHitsResultContainer = '.infinite-hits'}) => {
    // Create the render function
    const renderInfiniteHits = (renderOptions, isFirstRender) => {
        const {showMore, isLastPage, results} = renderOptions
        const $showMore = $(showMoreSelector)

        if (isFirstRender) {
            $showMore.click(showMore)
            return
        }

        $('.infiniteHitsCount').text(results.nbHits)
        $showMore.toggle(!isLastPage)

        if (results.nbHits === 0) {
            $(infiniteHitsResultContainer).html("<h3>No results found. Try removing some of your filters.</h3>")
            return
        }
        results.hits.map(item => {
            $(infiniteHitsResultContainer).append(`${template(item)}`)
        })
    }

    // Create the custom widget
    return instantsearch.connectors.connectInfiniteHits(renderInfiniteHits)
}

/**
 * Configuration Widget
 *
 * Configures the Algolia service. If internal user, disable analytics.
 *
 * analytics: bool if IP matches ATX IP
 *
 * https://www.algolia.com/doc/api-reference/widgets/configure/js/
 **/
const getConfigureWidget = ({analytics, filters, facetFilters}) => {
    const nowInSeconds = Math.round((new Date()).getTime() / 1000)
    const baseFilter = `main_object_publish_date<${nowInSeconds}`

    // If filters are passed we append the publish date filter
    filters = filters ? `${baseFilter} AND ${filters}` : baseFilter

    // https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
        return instantsearch.widgets.configure({analytics, filters, facetFilters})
}

export {
    getArticlesSearchFunction,
    getConfigureWidget,
    getCustomInifinteHits,
    getHotelsSearchFunction,
    getInstantSearch,
}
