import algoliasearch from 'algoliasearch'

import {getHotelsSearchFunction, getConfigureWidget, getCustomInifinteHits, getInstantSearch} from './common'
import * as articleTemplate from './templates/hotel.hbs'
import * as featuredHotelsTemplate from './templates/featured-hotels.hbs'


let indexPrefix, analytics, searchClient, tags, destinationTags

const setConfigParams = (options) => {
    searchClient = algoliasearch(options.appId, options.apiKey)
    indexPrefix = options.indexPrefix || ''
    analytics = options.analytics
    tags = options.tags
    destinationTags = options.destinationTags
}

const initHotelsPage = (options) => {
    setConfigParams(options)
    initInfiniteHotels()
    initFeaturedHotels()
}

const initFeaturedHotels = () => {
    const search = getInstantSearch({
        searchClient,
        indexPrefix,
        indexName: 'hotels-dated',
        hitsPerPage: 3,
    })

    search.addWidget({
        render(options) {
            const results = options.results;
            if (results.nbHits >= 3) {
                $('#featuredHotels').html(featuredHotelsTemplate({results: results.hits}))
            }
        },
    })
    search.addWidget(getConfigureWidget({analytics}))
    search.start()
}

const initInfiniteHotels = (options) => {
    if (options) {
        setConfigParams(options)
    }
    const indexName = 'hotels'
    const search = getInstantSearch({
        searchClient,
        indexPrefix,
        indexName,
        hitsPerPage: 10,
        searchFunction: getHotelsSearchFunction(`${indexPrefix}${indexName}`),
        facets: ['lineage', 'tags'],
    })

    search.on('render', function () {
        $(document).trigger('search:hotels', ['hotels', 'rendered']);
    })

    // Create the custom widget
    const customInfiniteHits = getCustomInifinteHits({template: articleTemplate})

    let facetFilters = null
    const destinationFilter = $('#destinationFilter').val()
    if (destinationFilter !== '') {
        facetFilters = [`lineage:${destinationFilter}`]
    }

    // Instantiate the custom widget
    search.addWidget(customInfiniteHits({container: '.infinite-hits', showPrevious: true}))
    search.addWidget(getConfigureWidget({analytics, facetFilters}))
    
    search.start()    
    if (destinationFilter !== '') {
        search.helper.addFacetRefinement('lineage', destinationFilter)
    }
    bindEvents(search)
}

const bindEvents = (search) => {
    $('#hotelsSorter').change(() => {
        $('.infinite-hits').empty()
        search.helper.search()
    })
    $('#destinationFilter').change((e) => {
        $('.infinite-hits').empty()
        search.helper.clearRefinements('lineage').addFacetRefinement('lineage', e.target.value).search()
    })
    $('.tags-filters').change(() => {
        search.helper.clearRefinements('tags')

        const tagsFilters = $('.tags-filters').filter((index, item) => $(item).is(':checked')).map((index, item) => item.value).toArray()
        tagsFilters.forEach((item) => {
            search.helper.addFacetRefinement('tags', item)
        })

        $('.infinite-hits').empty()
        search.helper.search()
    })
}

export {
    initHotelsPage,
    initInfiniteHotels,
}
