var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"container_1140\">\n    <div class=\"w-layout-grid grid_feature-main grid_nomarginbottom\">\n        <div id=\"w-node-5211d4aeefc0-55478101\" class=\"main_feature-large\" style=\"background:linear-gradient(rgba(20,20,20, .4),rgba(20,20,20, .4)), url("
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.image_url : stack1), depth0))
    + ") center / cover no-repeat;\">\n            <a href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.main_object_url : stack1), depth0))
    + "/\" class=\"black\">\n                <div class=\"img_gradient-cover-general\">\n                    <div class=\"feature_main-content feature_titleonly\">\n                        <h2 class=\"h2 light hotel_title_light mb10\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.title : stack1), depth0))
    + "</h2>\n                        <p class=\"p_s light\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.excerpt : stack1), depth0))
    + "</p>\n                    </div>\n                    <div class=\"hotel_rating\">\n                        <div class=\"hotel_rating-number\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.rating : stack1), depth0))
    + "</div>\n                    </div>\n                </div>\n            </a>\n        </div>\n        <div id=\"w-node-5211d4aeefca-55478101\" class=\"main_feature-small-1\" style=\"background:linear-gradient(rgba(20,20,20, .4),rgba(20,20,20, .4)), url("
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.image_url : stack1), depth0))
    + ") center / cover no-repeat;\">\n            <a href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.main_object_url : stack1), depth0))
    + "/\" class=\"black\">\n                <div class=\"img_gradient-cover-general\">\n                    <div class=\"feature_maincontent-small\">\n                        <h5 class=\"h5 light hotel_title_light pb10\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.title : stack1), depth0))
    + "</h5>\n                    </div>\n                    <div class=\"hotel_rating\">\n                        <div class=\"hotel_rating-number\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.rating : stack1), depth0))
    + "</div>\n                    </div>\n                </div>\n            </a>\n        </div>\n        <div id=\"w-node-5211d4aeefd2-55478101\" class=\"main_feature-small-2\" style=\"background:linear-gradient(rgba(20,20,20, .4),rgba(20,20,20, .4)), url("
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.image_url : stack1), depth0))
    + ") center / cover no-repeat;\">\n            <a href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.main_object_url : stack1), depth0))
    + "/\" class=\"black\">\n                <div class=\"img_gradient-cover-general\">\n                    <div class=\"feature_maincontent-small\">\n                        <h5 class=\"h5 light hotel_title_light pb10\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.title : stack1), depth0))
    + "</h5>\n                    </div>\n                    <div class=\"hotel_rating\">\n                        <div class=\"hotel_rating-number\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.rating : stack1), depth0))
    + "</div>\n                    </div>\n                </div>\n            </a>\n        </div>\n    </div>\n</div>\n";
},"useData":true});