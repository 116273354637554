import algoliasearch from 'algoliasearch'
import instantsearch from 'instantsearch'

import {getArticlesSearchFunction, getConfigureWidget, getHotelsSearchFunction, getInstantSearch} from './common'

/**
 * To build search.js => npm run build
 **/

let indexPrefix, routing, analytics, searchClient

const setConfigParams = (options) => {
    searchClient = algoliasearch(options.appId, options.apiKey)
    indexPrefix = options.indexPrefix || ''
    routing = options.routing
    analytics = options.analytics
}

/**
 * Return a Font-Awesome icon for an object type.
 *
 * We are using FA 3.2.1.
 * https://fontawesome.com/v3.2.1/icons/
 *
 * @param {String} objType
 * @return {String} Font-Awesome icon
 **/
const getObjectIcon = (objType) => {
    const iconMap = {
        'Default': 'icon-info-sign',
        'Article': 'icon-file-text',
        'Destination': 'icon-globe',
        'Itinerary': 'icon-map-marker',
        'Hotel': 'icon-building',
    }
    return iconMap[objType] || iconMap['Default']
}

/**
 * Pluralize facet labels.
 *
 * Algolia doesn't have a way to store
 * pluralized versions of an object's
 * type. Thus, we translate here if the
 * facet contains more than one result.
 *
 * @param {String} count
 * @param {String} objType
 * @return {String} Plural label
 **/
const pluralize = (count, objType) => {
    const pluralValues = {
        'Default': objType,
        'Article': 'Articles',
        'Destination': 'Destinations',
        'Itinerary': 'Itineraries',
        'Hotel': 'Hotels',
    };
    if (count > 1) {
        return pluralValues[objType] || pluralValues['Default']
    } else {
        return pluralValues['Default']
    }
}

/**
 * Init Stats widget.
 *
 * Binds the Algolia Stats widget to the footer of the page. Shows
 * some basic stats about how long it took to search. Interesting?
 *
 * container: div to bind to
 *
 * https://community.algolia.com/instantsearch.js/v2/widgets/stats.html
 **/
const getStatsWidget = (container) => instantsearch.widgets.stats({container: container})

/**
 * Init Pagination widget.
 *
 * Binds the Algolia Pagination widget to the search results HTML page.
 * This is how you go to the next/previous page of results.
 *
 * container: div to bind to
 * maxPages: total number of pages possible
 * scrollTo: scroll back to top of hits container
 * showFirstLast: we don't want this.
 * cssClasses: css overrides for paginator
 *
 * https://community.algolia.com/instantsearch.js/v2/widgets/pagination.html
 **/
const getPaginationWidget = (container) => instantsearch.widgets.pagination({
    container: container,
    maxPages: 20,
    scrollTo: true,
    showFirstLast: false,
    cssClasses: {
        root: 'pagination',
        active: 'active',
        disabled: 'disabled'
    }
})

/**
 * Init Hits widget.
 *
 * Binds the Algolia Hits widget to the search results HTML page.
 * This is where all result data is piped to!
 *
 * container: div to bind to
 * escapeHits: escape HTML, yes!
 * transformData: callback to obtain icons for object type.
 * templates: empty, item... Handlebars templates.
 *
 * https://community.algolia.com/instantsearch.js/v2/widgets/hits.html
 **/
const getHitsWidget = (container, objectType, template) => {
    var hitsWidget = instantsearch.widgets.hits({
        container: container,
        escapeHits: true,
        transformData: {
            item: (obj) => {
                obj.icon = getObjectIcon(obj.main_object_type)
                return obj
            }
        },
        templates: {
            empty: 'Sorry, no items match your search query. Try broadening your query.',
            item: (obj) => {
                if (obj.main_object_type !== objectType) {
                    return
                }
                const templateName = template ? template : obj.main_object_type.toLowerCase()
                return Handlebars.templates['search/results/' + templateName](obj)
            },
        }
    });
    hitsWidget.superRender = hitsWidget.render
    return hitsWidget
}

const getArticlesSearch = (nestedSearchers) => {
    const indexName = 'articles'
    const articlesSearch = getInstantSearch({
        searchClient,
        indexPrefix,
        indexName,
        hitsPerPage: 8,
        routing,
        searchFunction: getArticlesSearchFunction(`${indexPrefix}${indexName}`, nestedSearchers)
    })

    /**
     * Init SearchBox widget.
     *
     * Binds the Algolia SearchBox widget to the
     * search input on the search HTML page.
     *
     * container: div to bind to
     * placeholder: placeholder text
     * reset: X icon to clear input field
     * magnifier: magnifier glass icon in input field
     *
     * https://community.algolia.com/instantsearch.js/v2/widgets/searchBox.html
     **/
    const searchBox = instantsearch.widgets.searchBox({
        container: '#search-box-input',
        placeholder: 'What can we help you find?',
        reset: true,
        magnifier: true
    })

    const articlesHits = getHitsWidget('#articlesHitsPrimary', 'Article')
    articlesHits.render = (opts) => {
        const results = opts.results
        $('#articlesCount').text(results.nbHits)
        // read the hits from the results and transform them into HTML.
        const template = Handlebars.templates['search/results/article']
        const $primary = $('#articlesHitsPrimary')
        const $secondary = $('#articleHitsSecondary')
        $primary.empty()
        $secondary.empty()
        if (results.hits.length === 0) {
            $primary.html('Sorry, no items match your search query. Try broadening your query.')
            return
        }
        
        $primary.html(results.hits.slice(0, Math.ceil(results.hits.length / 2)).map(obj => template(obj)).join(''))
        $secondary.html(results.hits.slice(Math.ceil(results.hits.length / 2)).map(obj => template(obj)).join(''))
    }

    articlesSearch.addWidget(searchBox)
    articlesSearch.addWidget(articlesHits)
    articlesSearch.addWidget(getPaginationWidget('#articlesPagination'))
    articlesSearch.addWidget(getStatsWidget('#articlesStats'))
    articlesSearch.addWidget(getConfigureWidget({analytics}))

    return articlesSearch
}

const getHotelPreviewSearch = () => {
    const hotelPreviewSearch = getInstantSearch({searchClient, indexPrefix, indexName: 'hotels', hitsPerPage: 4})
    const hotelsPreview = getHitsWidget('#hotelsPreviewHits', 'Hotel', 'hotelPreview')
    hotelPreviewSearch.addWidget(hotelsPreview)
    return hotelPreviewSearch
}

const getDestinationSearch = () => {
    const destinationSearch = getInstantSearch({searchClient, indexPrefix, indexName: 'destinations', hitsPerPage: 10})
    const destinations = getHitsWidget('#destinationHits', 'Destination')
    destinations.render = (opts) => {
        destinations.superRender(opts)
        $('#destinationHits').parent().toggle(opts.results.nbHits > 0)
    }
    destinationSearch.addWidget(destinations)

    return destinationSearch
}

const getHotelsSearch = () => {
    const indexName = 'hotels'
    const hotelsSearch = getInstantSearch({
        searchClient, 
        indexPrefix, 
        indexName: indexName, 
        searchFunction: getHotelsSearchFunction(`${indexPrefix}${indexName}`)
    })
    
    hotelsSearch.on('render', function () {
        $(document).trigger('search:hotels', ['hotels', 'rendered']);
    })
    
    const hotels = getHitsWidget('#hotelsHits', 'Hotel');
    hotels.render = (opts) => {
        hotels.superRender(opts)
        $('#hotelsCount').text(opts.results.nbHits)
        $('#hotelsPreviewHits').toggle(opts.results.nbHits > 0)
    }

    hotelsSearch.addWidget(hotels)
    hotelsSearch.addWidget(getPaginationWidget('#hotelsPagination'))
    hotelsSearch.addWidget(getStatsWidget('#hotelsStats'))
    hotelsSearch.addWidget(getConfigureWidget({analytics}))

    return hotelsSearch
}

const getItinerarySearch = () => {
    const searchFunction = (helper) => {
        const query = helper.state.query

        const currentIndex = helper.state.index
        const sortBy = $('#itinerariesSorter').val()
        let replica
        switch (sortBy) {
            case 'recommended':
                replica = 'engagement'
                break
            case 'newest':
                replica = 'dated'
                break
            case 'oldest':
                replica = 'oldest'
                break
            default:
                replica = 'engagement'
                break
        }

        const newIndex = `${itinerarySearch.indexName}${replica === '' ? '' : `-${replica}`}`

        if (currentIndex !== newIndex) {
            helper.setIndex(newIndex)
        }
        helper.search()
    }

    const itinerarySearch = getInstantSearch({searchClient, indexPrefix, indexName: 'itineraries', searchFunction})
    const itineraries = getHitsWidget('#itinerariesHits', 'Itinerary')
    itineraries.render = (opts) => {
        itineraries.superRender(opts)
        $('#itinerariesCount').text(opts.results.nbHits)
    }
    itinerarySearch.addWidget(itineraries);
    itinerarySearch.addWidget(getPaginationWidget('#itinerariesPagination'))
    itinerarySearch.addWidget(getStatsWidget('#itinerariesStats'))
    itinerarySearch.addWidget(getConfigureWidget({analytics}))

    return itinerarySearch
}

/**
 * Algolia Search Handler
 *
 * Binds the InstantSearch.js library to various assets on
 * the search results HTML page.
 *
 * https://community.algolia.com/instantsearch.js/
 *
 * @typedef {Object} algoliaSearch
 */
const Search = (options) => {
    setConfigParams(options)
    const hotelsSearch = getHotelsSearch()
    const itinerarySearch = getItinerarySearch()
    const instantSearchers = [getHotelPreviewSearch(), getDestinationSearch(), hotelsSearch, itinerarySearch]
    const articlesSearch = getArticlesSearch(instantSearchers)

    instantSearchers.map((instantSearch) => instantSearch.start())
    articlesSearch.start()

    $('#priceFilter').slider({
        range: 'min',
        min: 0,
        max: 2000,
        step: 200,
        value: 2000,
        slide: function (event, ui) {
            $('#priceFilterValue').val(ui.value);
            var delay = function () {
                positionSelectedPrice(ui.handle, ui.value);
            };
            // wait for the ui.handle to set its position
            setTimeout(delay, 5);

            if (hotelsSearch.helper.hasRefinements('highest_rate')) {
                hotelsSearch.helper.clearRefinements('highest_rate')
            }
            if (ui.value < 2000) {
                hotelsSearch.helper.addNumericRefinement('highest_rate', '<', `${ui.value}`)
            }
            hotelsSearch.helper.search()
        }
    });

    $('#hotelsSorter').change(() => hotelsSearch.helper.search())
    $('#articlesSorter').change(() => articlesSearch.helper.search())
    $('#itinerariesSorter').change(() => itinerarySearch.helper.search())
}


function changeTab() {
    $('.refine-tabs .ais-menu--item.ais-menu--item__active').removeClass('ais-menu--item__active')
    $('.search-tabs .active').removeClass('active')
    const self = $(this);
    self.addClass('ais-menu--item__active')
    $('[data-tab=' + self.data('tab-trigger') + ']').addClass('active')
    $('html, body').animate({scrollTop: 100}, 500, function () {
        if (self.data('tab-trigger') === 'hotels') {
            positionSelectedPrice($('.ui-slider-handle'), $('#priceFilter').slider('value'))
        }
    })
}

/**
 * Format Datetime
 *
 * Builds datetime HTML to append to the template.
 *
 **/
Handlebars.registerHelper('formatDateTime', (datetime) => {
    if (typeof(datetime) === 'undefined' || datetime == null) {
        return 'N/A';
    }
    return moment(datetime * 1000, 'x').format('MMMM DD, YYYY')
})


Handlebars.registerHelper('highlight', (content) => {
    if (typeof(content) === 'string') {
        while (content.indexOf('__ais-highlight__') > -1) {
            content = content.replace('__ais-highlight__', '<em>').replace('__/ais-highlight__', '</em>')
        }
    }
    return content
})


/**
 * Format Number
 *
 * Pretty print a number.
 *
 **/
Handlebars.registerHelper('formatNumber', (number) => {
    return Number(number).toLocaleString()
})

// Handlebar helpers
Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
    switch (operator) {
        case '==':
            return (v1 == v2) ? options.fn(this) : options.inverse(this)
        case '===':
            return (v1 === v2) ? options.fn(this) : options.inverse(this)
        case '<':
            return (v1 < v2) ? options.fn(this) : options.inverse(this);
        case '<=':
            return (v1 <= v2) ? options.fn(this) : options.inverse(this)
        case '>':
            return (v1 > v2) ? options.fn(this) : options.inverse(this)
        case '>=':
            return (v1 >= v2) ? options.fn(this) : options.inverse(this)
        case '&&':
            return (v1 && v2) ? options.fn(this) : options.inverse(this)
        case '||':
            return (v1 || v2) ? options.fn(this) : options.inverse(this)
        default:
            return options.inverse(this)
    }
})

const positionSelectedPrice = (handle, value) => {
    $('#maxPriceFilter').html('$' + value + (value === 2000 ? '+' : '')).position({
        my: 'center top-40',
        of: handle
    })
}

export {
    Search,
    changeTab,
}
