var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <div id=\"w-node-912342e3874d-2f599c7e\" class=\"main_feature-small-2\"\n         style=\"background:linear-gradient(rgba(20,20,20, .4),rgba(20,20,20, .4)), url("
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.image_url : stack1), depth0))
    + ") center / cover no-repeat;\">\n        <a href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.main_object_url : stack1), depth0))
    + " \" class=\"black\">\n            <div class=\"img_gradient-cover-general\">\n                <div class=\"feature_maincontent-small\">\n                    <h5 class=\"h5 light hotel_title_light\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.title : stack1), depth0))
    + "</h5>`\n                </div>\n                <div class=\"hotel_rating\">\n                    <div class=\"hotel_rating-number\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.rating : stack1), depth0))
    + "</div>\n                </div>\n            </div>\n        </a>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "<div class=\"w-layout-grid grid_feature-main grid_nomarginbottom\" id=\""
    + alias2(((helper = (helper = helpers.idBase || (depth0 != null ? depth0.idBase : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"idBase","hash":{},"data":data}) : helper)))
    + "-group\">\n    <div id=\"w-node-912342e38745-2f599c7e\" class=\"main_feature-large\"\n         style=\"background:linear-gradient(rgba(20,20,20, .4),rgba(20,20,20, .4)), url("
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.image_url : stack1), depth0))
    + ") center / cover no-repeat;\">\n        <a href=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.main_object_url : stack1), depth0))
    + " \" class=\"black\">\n            <div class=\"img_gradient-cover-general\">\n                <div class=\"feature_main-content feature_titleonly\">\n                    <h2 class=\"h2 light hotel_title_light\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.title : stack1), depth0))
    + "</h2>\n                    <p class=\"p_s light\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.excerpt : stack1), depth0))
    + "</p>\n                </div>\n                <div class=\"hotel_rating\">\n                    <div class=\"hotel_rating-number\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.rating : stack1), depth0))
    + "</div>\n                </div>\n            </div>\n        </a>\n    </div>\n    <div id=\"w-node-912342e38749-2f599c7e\" class=\"main_feature-small-1\"\n         style=\"background:linear-gradient(rgba(20,20,20, .4),rgba(20,20,20, .4)), url("
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.image_url : stack1), depth0))
    + ") center / cover no-repeat;\">\n        <a href=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.main_object_url : stack1), depth0))
    + " \" class=\"black\">\n            <div class=\"img_gradient-cover-general\">\n                <div class=\"feature_maincontent-small\">\n                    <h5 class=\"h5 light hotel_title_light\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.title : stack1), depth0))
    + "</h5>`\n                </div>\n                <div class=\"hotel_rating\">\n                    <div class=\"hotel_rating-number\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.rating : stack1), depth0))
    + "</div>\n                </div>\n            </div>\n        </a>\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1["2"] : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n<div>Featuring most recent additions | Editor rating scale 90-100</div>\n";
},"useData":true});