var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<a href=\""
    + alias4(((helper = (helper = helpers.main_object_url || (depth0 != null ? depth0.main_object_url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"main_object_url","hash":{},"data":data}) : helper)))
    + "/\" class=\"black\">\n    <div class=\"hotel_list-thumbnail\">\n        <div class=\"w-layout-grid grid_hotel-thumbnail\">\n            <div id=\"w-node-561d0b75a790-55478101\" class=\"hotel_img-thumbnail-1\" style=\"background-image: url("
    + alias4(((helper = (helper = helpers.image_url || (depth0 != null ? depth0.image_url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image_url","hash":{},"data":data}) : helper)))
    + ")!important;\"></div>\n            <div id=\"w-node-561d0b75a791-55478101\" class=\"hotel_description-wrap\">\n                <h5 class=\"h5 article-list\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h5>\n                <p class=\"hotel_review-description\">"
    + alias4(((helper = (helper = helpers.excerpt || (depth0 != null ? depth0.excerpt : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"excerpt","hash":{},"data":data}) : helper)))
    + "</p>\n            </div>\n        </div>\n    </div>\n</a>\n";
},"useData":true});